<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		 <a-tabs default-active-key="4" >
			 <a-tab-pane key="4" tab="For categories">
				<!-- Products Table -->
				<a-row :gutter="24" type="flex">
					<!-- Products Table Column -->
					<a-col :span="24" class="mb-24">
						<!-- Products Table Card -->
						<CardGoalTable
							:data="tableDataCategory"
							:columns="table1Columns"
							goaltype="4"
						></CardGoalTable>
						<!-- / Products Table Card -->
					</a-col>
					<!-- / Products Table Column -->
				</a-row>
				<!-- / Products Table -->
			 </a-tab-pane>
			 <a-tab-pane key="2" tab="For products">
				 <a-row :gutter="24" type="flex">
					<!-- Products Table Column -->
					<a-col :span="24" class="mb-24">
						<!-- Products Table Card -->
						<CardGoalTable
							:data="tableDataProduct"
							:columns="table1Columns"
							goaltype="2"
						></CardGoalTable>
						<!-- / Products Table Card -->
						
					</a-col>
					<!-- / Products Table Column -->
				</a-row>
			 </a-tab-pane>
			 <a-tab-pane key="1" tab="For all products">
				 <a-row :gutter="24" type="flex">
					<!-- Products Table Column -->
					<a-col :span="24" class="mb-24">
						<!-- Products Table Card -->
						<CardGoalTable
							:data="tableDataAllProducts"
							:columns="table1Columns"
							goaltype="1"
						></CardGoalTable>
						<!-- / Products Table Card -->
						
					</a-col>
					<!-- / Products Table Column -->
				</a-row>
			 </a-tab-pane>
		 </a-tabs>
		<a-button class="fab" shape="circle" @click="addGoal">
			<font-awesome-icon icon="plus"/>
		</a-button>
	</div>
</template>

<script>

	// "Products" table component.
	import CardGoalTable from '../components/Cards/CardGoalTable' ;

	import request from '../utils/request';
	import {list} from '@/api/goal';
	
	// "Products" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			
		},
		{
			title: 'Description',
			dataIndex: 'description',
		
		},
		{
			title: 'KPI',
			dataIndex: 'kpi',
			
		},
		{
			title: 'Relationship',
			dataIndex: 'relationship',
			
		},
		{
			title: 'Threshold (%)',
			dataIndex: 'threshold',
			
		},
		{
			title: '',
			dataIndex:'goalID',
			scopedSlots: { customRender: 'editBtn' },
			width: 50,
		},
		{
			title: '',
			dataIndex:'goalID',
			key:'id2',
			scopedSlots: { customRender: 'delBtn' },
			width: 50,
		},
		{
			title: '',
			dataIndex:'goalID',
			key:'n',
			scopedSlots: { customRender: 'navBtn' },
			width: 50,
		},
	];

	// "Authors" table list of rows and their properties.
	const tableDataCategory = [];
	const tableDataProduct = [];
	const tableDataAllProducts = [];
	
	export default ({
		components: {
			CardGoalTable,
		},
		methods: {
			addGoal(){
				this.$router.push({path:'/goaledit',query:{goalid:0}});
			},
			testping(){
				alert('test');
				request.get('/api/ping')
			}
		},
		data() {
			return {
				// Associating "Products" table columns with its corresponding property.
				table1Columns: table1Columns,
				tableDataCategory:[],
				tableDataProduct:[],
				tableDataAllProducts:[]
			}
		},
		mounted() {
			list().then(data=>{
				this.table1Data = data;
				this.tableDataCategory = [];
				this.tableDataProduct = [];
				this.tableDataAllProducts =[];

				for (var i=0;i<data.length;i++)
				{
					if (data[i].categorieswithtype){
						for (var j=0;j<data[i].categorieswithtype.length;j++){
							let ctype = data[i].categorieswithtype[j].categorytype;

							if (ctype==4)
								this.tableDataCategory.push(data[i]);
							if (ctype==2)
								this.tableDataProduct.push(data[i]);
							if (ctype==1)
								this.tableDataAllProducts.push(data[i]);
						}
					} else {
						this.tableDataCategory.push(data[i]);
					}
				}
			});
		},
	})

</script>

<style lang="scss">
</style>